<template>
  <div id="app">
    <v-app id="inspire">
      <span class="backroundimage"></span>
      <v-container fluid fill-height>
        <v-layout align-center justify-center>
          <v-flex xs12 sm8 md7>
            <v-card class="elevation-12" color="#3fbec7f5">
              <div class="logo">
                <v-img src="../assets/nageela_logo_med.png"></v-img>
              </div>
              <h3 class="text-center">Register For Camp Mail</h3>
              <br />
              <v-form ref="form" v-model="valid" lazy-validation>
                <v-container>
                  <v-row>
                    <v-col cols="12" md="6">
                      <v-text-field
                        v-model="firstName"
                        prepend-icon="mdi-account"
                        :disabled="loading1"
                        :rules="[v => !!v || 'First Name is required']"
                        label="First Name"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field
                        v-model="lastName"
                        prepend-icon="mdi-account"
                        :disabled="loading1"
                        :rules="[v => !!v || 'Last Name is required']"
                        label="Last Name"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="6">
                      <v-text-field
                        v-model="email"
                        :disabled="loading1"
                        :rules="[v => !!v || 'E-mail is required', v => (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(v)) || 'E-mail must be valid']"
                        label="Email"
                        prepend-icon="mdi-email"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="6">
                      <v-text-field
                        v-on:keyup.enter="register"
                        v-model="password"
                        :disabled="loading1"
                        label="Password"
                        name="password"
                        prepend-icon="mdi-lock"
                        :type="showPassword ? 'text' : 'password'"
                        :rules="[v => !!v || 'Password is required', v => (v && v.length >= 6) || 'Password must have more than 6 characters']"
                        :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                        @click:append="showPassword = !showPassword"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-switch
                      v-model="newsLetter"
                      class="mt-0"
                      color="#FFA500"
                      hide-details
                      label="Get Updates About Camp"
                    ></v-switch>
                  </v-row>
                </v-container>
              </v-form>
              <div class="danger-alert" v-html="error" />
              <v-divider></v-divider>
              <v-card-actions>
                <v-btn rounded @click="backToLoginPage" color="primary">Return To Login</v-btn>
                <v-spacer></v-spacer>
                <v-btn
                  rounded
                  :loading="loading1"
                  color="primary"
                  depressed
                  @click="register"
                >Register</v-btn>
              </v-card-actions>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </v-app>
  </div>
</template>
<script>
// @ is an alias to /src
import userService from "../services/Users";
export default {
  name: "SendMessage",
  components: {},
  data() {
    return {
      loading1: false,
      isUpdating: false,
      firstName: null,
      lastName: null,
      email: null,
      password: null,
      newsLetter: true,
      showPassword: false,
      valid: true,
      error: null
    };
  },
  async mounted() {
    if (this.$store.state.status >= 1) this.$router.push({ name: "Home" });
  },
  methods: {
    async register() {
      if (this.$refs.form.validate())
        try {
          const response = await userService.register({
            firstname: this.firstName,
            lastname: this.lastName,
            email: this.email,
            password: this.password,
            newsletter: this.newsLetter
          });
          await this.$store.dispatch("authentication/login", response.data);
          this.$router.push({ name: "Home" });
        } catch (err) {
          this.error = err.response.data.error;
        }
    },
    backToLoginPage() {
      this.$router.push({ name: "Login" });
    }
  }
};
</script>
<style scoped>
.backroundimage {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: url("../assets/login_background.jpg")
    no-repeat center center;
  background-size: cover;
  filter: brightness(50%);
}
.logo {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 160px;
  height: 160px;
  margin: 0 auto;
}
</style>